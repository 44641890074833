import React, { useEffect, useLayoutEffect } from "react";
import { ScrollAnimation } from "./scroll_animation";

export type GridProps = {
    children?: React.ReactElement[];
    columns: number;
    scrollAnimation?: boolean;
    scrollAnimationThreshold?: number;
    scrollAnimationDuration?: number;
    processCounter?: number;
    square?: boolean;
    singleTopMargin?: number;
    onReady?: () => void;
    singleCentered?: boolean;
    verticalCellSpacing?: number;
    horizontalCellSpacing?: number | {fraction: number};
    extendItemHeightBy?: number;
};

export function Grid({
    children = [],
    columns,
    verticalCellSpacing = 15,
    horizontalCellSpacing = 15,
    scrollAnimation = false,
    scrollAnimationThreshold,
    scrollAnimationDuration,
    processCounter = 0,
    square = false,
    singleTopMargin = 0,
    onReady = () => {},
    singleCentered = false,
    extendItemHeightBy = 0

}: GridProps)
{
    useEffect(onReady,[]);

    const isSingleCentered = ((children.length<columns) && (singleCentered));
    const isSingle = (children.length<=columns);
    const { itemWidthCalc,horizontalCellSpacingActual } = (() => {
        if ((typeof horizontalCellSpacing==="object") && (typeof horizontalCellSpacing.fraction==="number"))
        {
            const total = (columns+(columns-1)*horizontalCellSpacing.fraction);
            return {
                // itemWidthCalc: `(${Math.round((1/total)*100000)/1000}%)`,
                itemWidthCalc: `(${(1/total)*100}%)`,
                horizontalCellSpacingActual: `(${(horizontalCellSpacing.fraction/total)*100}%)`
            };
        }
        else
        {
            return {
                itemWidthCalc: `((100% - (${horizontalCellSpacing}rem * ${columns-1}))/${columns})`,
                horizontalCellSpacingActual: `(${horizontalCellSpacing}rem)`
            };
        }
    })();    

    return (
        <>
            <div className={"grid"+(square?" square":"")+(isSingleCentered?" single-centered":"")} style={{paddingTop: isSingle?singleTopMargin:0}}>
                {children.map((item,index) => (
                    <React.Fragment key={index}>
                        <div className="item" style={{
                            width: `calc(${itemWidthCalc})`,
                            ...(square?{padding: `calc(${itemWidthCalc} / 2)`}:{})
                        }}>
                            <div className="frame"> 
                                {scrollAnimation?<ScrollAnimation processCounter={processCounter} threshold={scrollAnimationThreshold} animationDuration={scrollAnimationDuration}>{item}</ScrollAnimation>:item}
                            </div>
                        </div>
                        {((index%columns!==columns-1) && !(isSingleCentered && (index===children.length-1)))?(
                            <div className="column-spacer" style={{
                                width: `calc(${horizontalCellSpacingActual})`
                            }}></div>
                        ):((index<children.length-1)?(
                            <div className="row-spacer" style={{
                                height: `${verticalCellSpacing}rem`
                            }}></div>
                        ):null)}
                    </React.Fragment>
                ))}
            </div>
            <style jsx>{`
            .grid {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                width: 100%;
            }

            .grid.single-centered {
                flex-wrap: nowrap;
                justify-content: center;
            }

            .frame {
                height: 100%;
            }

            .item {
                box-sizing: border-box;
                position: relative;
                height: auto;
            }
            .grid.square .item .frame {
                position: absolute;
                left: 0rem;
                top: 0rem;
                width: 100%;
                height: 100%;
            }
            
            .column-spacer {
                height: 0rem;
            }

            .row-spacer {
                width: 100%;
            }
            `}</style>
        </>
    );    
}