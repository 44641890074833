import { useContext, useEffect, useRef, useState } from "react";
import { LayoutStateContext } from "../../contexts/layout_state";
import { WindowSizeContext } from "../../contexts/window_size";
import { misc } from "../../lib/misc";

export type ScrollAnimationProps = {
    children?: JSX.Element;
    threshold?: number;
    animationDuration?: number;
    processCounter?: number;
};

export function ScrollAnimation({
    children,
    threshold = 0.6,
    animationDuration = 0.7,
    processCounter = 0}:ScrollAnimationProps)
{
    const containerRef = useRef<HTMLDivElement>();

    const [show,setShow] = useState(false);
    const { page } = useContext(LayoutStateContext);
    const windowSize = useContext(WindowSizeContext);
    const firstCalledRef = useRef(false);
    const unregisterRef = useRef(() => {});

    const processScreenRatio = () => 
    {
        if (containerRef.current)
        {
            if (!firstCalledRef.current)
            {
                firstCalledRef.current = true;
                setTimeout(processScreenRatio,100);
                return;
            }
            const onScreenRatio = (() => 
            {
                let { top,bottom,height } = containerRef.current.getBoundingClientRect();
                if (height===0) return 0;                    
                const { height: screenHeight } = misc.getWindowSize();
                const isOnScreen = (value: number) => (value>=0) && (value<=screenHeight);
                if ((isOnScreen(top)) && (isOnScreen(bottom))) return 1;
                if ((!isOnScreen(top)) && (isOnScreen(bottom))) return bottom/height;
                if ((isOnScreen(top)) && (!isOnScreen(bottom))) return (screenHeight-top)/height;
                if ((!isOnScreen(top)) && (!isOnScreen(bottom))) 
                {
                    if ((top<0) && (bottom>screenHeight)) return screenHeight/height;
                    return 0;
                }
            })();
            if (onScreenRatio>=threshold)
            {
                unregisterRef.current();
                setShow(true);
            }    
        }
    };

    useEffect(() => 
    {
        processScreenRatio();
    },[windowSize,processCounter]);

    useEffect(() => 
    {
        const listener = () => 
        {            
            processScreenRatio();
        };
        page.addEventListener("scroll",listener);
        setTimeout(listener,100);

        let unregistered = false;

        return unregisterRef.current = () => 
        {
            if (!unregistered)
            {
                page.removeEventListener("scroll",listener);
                unregistered = true;
            }
        };
    },[]);

    return (
        <>
            <div ref={containerRef} className={`container${show?" show":""}`} style={{
                    transition: `opacity ${animationDuration}s,visibility ${animationDuration}s`
                }}>
                {children?children:""}
            </div>
            <style jsx>{`
            .container {
                opacity: 0;
                visibility: hidden;
                width: 100%;
                height: 100%;
            }
            .container.show {
                opacity: 1;
                visibility: visible;
            }
            `}</style>
        </>
    );
}