import { useContext, useEffect, useRef, useState } from 'react';
import { GlobalStateContext } from '../contexts/global_state';
import { TWindowSize, WindowSizeContext } from '../contexts/window_size';
import { EventPane } from './core/event_pane';
import { misc } from "../lib/misc";
import { useResizeObserver } from '../hooks/use_resize_observer';
import { ResizeObserverCallbackUpdate } from '../hooks/use_resize_observer/types';
import { LayoutStateContext } from '../contexts/layout_state';
import { Image } from './common/scaled-next-image';

export type TText = {
    text: string;
    left: number;
    top: number;
    family: string;
    size: number;
    weight: number;
    color: string;
    url?: string;
    letterSpacing?: number;
    paddingLeft?: number;
    paddingRight?: number;
};

export enum TBannerLayout {
    Left,
    Center,
    Right
};

type TProp = {
    src: string;
    aspectRatio: number;
    height: number;
    texts: TText[];
    layout: TBannerLayout;
    onImageShowing?: () => void;
    onHeightReady?: () => void;
    onResize?: (update: ResizeObserverCallbackUpdate<HTMLDivElement>) => void;
    onClick?: () => void; 
};

export default function Banner({aspectRatio,src,height,texts,layout,onImageShowing = () => {},onHeightReady = () => {},onResize,onClick}:TProp)
{
    const [show,setShow] = useState(false);    
    const { animation } = useContext(GlobalStateContext);
    const { scaleFactor } = useContext(LayoutStateContext);
    const _windowSize = useContext(WindowSizeContext);
    const [actualHeight,_setActualHeight] = useState(0);
    const [windowSize,setWindowSize] = useState<TWindowSize>({width: 0,height: 0});
    const [textAspectRatios,setTextAspectRatios] = useState(Array.from(Array(texts.length)).map(() => 1));
    const firstHeightSetRef = useRef(false);

    const setActualHeight = (value: number) => 
    {
        _setActualHeight(value);
        if (!firstHeightSetRef.current)
        {
            firstHeightSetRef.current = true;
            setTimeout(onHeightReady,0);
        }
    };

    const percentageSize = (percentage: number,height = actualHeight) => (percentage/100)*height;

    const getTextWidth = (index: number) => percentageSize(texts[index].size)*textAspectRatios[index];

    useEffect(() => 
    {
        setTextAspectRatios(texts.map(({ text,family,size,weight,letterSpacing }) => misc.getTextAspectRatio(text,family,weight,letterSpacing)));                
    },[]);

    useEffect(() => 
    {
        setWindowSize(_windowSize);
        if (layout===TBannerLayout.Center)
        {
            if ((_windowSize.width/height)<aspectRatio)
            {
                setActualHeight(_windowSize.width/aspectRatio);
            }
            else
            {
                setActualHeight(height);
            }    
        }
        else
        {
            if (texts.length>0)
            {
                if (texts.length===1)
                {
                    const { text,family,size,weight,paddingLeft = 0,paddingRight = 0,letterSpacing } = texts[0];
                    const firstTextWidth = misc.getTextWidth(text,family,percentageSize(size,height),weight,letterSpacing);

                    const imageWidth = height*aspectRatio;
                    const availableWidth = _windowSize.width-(imageWidth+paddingLeft+paddingRight);
                    if (firstTextWidth<=availableWidth)
                    {
                        setActualHeight(height);
                    }
                    else
                    {
                        const firstTextAspectRatio = misc.getTextAspectRatio(text,family,weight,letterSpacing);
                        setActualHeight((_windowSize.width-(paddingLeft+paddingRight))/(firstTextAspectRatio*(size/100)+aspectRatio));
                    }    
                }
                else 
                {
                    const getTextWidth = (index: number) =>
                    {
                        const { text,family,size,weight,letterSpacing } = texts[index];
                        return misc.getTextWidth(text,family,percentageSize(size,height),weight,letterSpacing);
                    };
                    const getTextAspectRatio = (index: number) =>
                    {
                        const { text,family,weight,letterSpacing } = texts[index];
                        return misc.getTextAspectRatio(text,family,weight,letterSpacing);
                    };
                    const firstTextWidth = getTextWidth(0);
                    const secondTextWidth = getTextWidth(1);
                    const firstTextAspectRatio = getTextAspectRatio(0);
                    const secondTextAspectRatio = getTextAspectRatio(1);
                    const firstSize = texts[0].size/100;
                    const secondSize = texts[1].size/100;                                        
                    const rs = (firstTextWidth>=secondTextWidth)?firstTextAspectRatio*firstSize:secondTextAspectRatio*secondSize;
                    const c = Math.abs(firstTextAspectRatio*firstSize-secondTextAspectRatio*secondSize);                    
                    const e = Math.max(Math.abs(texts[1].left)-1,0);
                    const u = e*c*height/2;

                    const { paddingLeft = 0,paddingRight = 0 } = texts[0];

                    const imageWidth = height*aspectRatio;
                    const availableWidth = _windowSize.width-(imageWidth+paddingLeft+paddingRight);

                    if ((rs*height+u)<=availableWidth)
                    {
                        setActualHeight(height);
                    }
                    else
                    {
                        setActualHeight((_windowSize.width-(paddingLeft+paddingRight))/(rs+(e*c/2)+aspectRatio));
                    }
                }
            }
            else
            {
                setActualHeight(height);
            }
        }
    
    },[`${_windowSize.height}-${_windowSize.width}`,height]);

    const width = actualHeight*aspectRatio;

    const fadeInDuration = 2;

    const imageLoadedHandler = () => 
    {        
        setShow(true);
        setTimeout(onImageShowing,fadeInDuration*1000/2);    
    };
    
    const textFadeInDuration = 1.2;
    const textShowRatio = 0.6;
    const delayPerText = 1;

    let textLefts = Array.from(Array(texts.length)).map(() => 0);

    if ((layout!==TBannerLayout.Center) && (texts.length>0))
    {
        const firstTextWidth = getTextWidth(0);
        const { left,paddingLeft = 0,paddingRight = 0 } = texts[0];
        let rangeLeft = paddingLeft;
        let rangeRight = (windowSize.width-width)-paddingRight;
        const leftRatio = (Math.min(Math.max(left,-1),1)+1)/2;
        if (layout===TBannerLayout.Left)
        {
            rangeLeft+= width;
            rangeRight+= width;
        }
        const from = rangeLeft;
        let firstTextBoundariesLeft = 0;
        let firstTextBoundariesRight = 0;

        if (texts.length===1)
        {                        
            const to = rangeRight-firstTextWidth;            
            firstTextBoundariesLeft = from+(leftRatio*(to-from));
        }
        else 
        {
            const secondTextWidth = getTextWidth(1);
            const e = Math.max(Math.abs(texts[1].left)-1,0);
            const u = e*Math.abs(firstTextWidth-secondTextWidth)/2;
            const textBoxWidth = Math.max(firstTextWidth,secondTextWidth)+u;
            const to = rangeRight-textBoxWidth;
            const textBoxBoundariesLeft = from+(leftRatio*(to-from));
            const textBoxBoundariesRight = textBoxBoundariesLeft+textBoxWidth;

            if (firstTextWidth>=secondTextWidth)
            {
                if (texts[1].left<0)
                {
                    firstTextBoundariesLeft = textBoxBoundariesLeft+u;
                }
                else
                {
                    firstTextBoundariesLeft = textBoxBoundariesLeft;
                }                
            }
            else 
            {
                if (u>0)
                {
                    if (texts[1].left<0)
                    {
                        firstTextBoundariesLeft = textBoxBoundariesLeft;
                    }
                    else 
                    {
                        firstTextBoundariesLeft = textBoxBoundariesRight-firstTextWidth;
                    }
                }
                else 
                {
                    const from = textBoxBoundariesLeft;
                    const to = from+(secondTextWidth-firstTextWidth);
                    const mid = (from+to)/2;
                    firstTextBoundariesLeft = mid+((to-mid)*texts[1].left);
                }
            }            
        }

        firstTextBoundariesRight = firstTextBoundariesLeft+firstTextWidth;

        textLefts[0] = firstTextBoundariesLeft;
        texts.slice(1).forEach(({ left },_index) => 
        {
            const index = _index+1;
            const from = firstTextBoundariesLeft;
            const to = firstTextBoundariesRight-getTextWidth(index);
            const mid = (from+to)/2;
            textLefts[index] = mid+((to-mid)*left);
        });
    }

    const refOnResize = useResizeObserver(onResize,{ disabled: !onResize });

    return (
        <>
            <div ref={refOnResize} className={`container${show?" show":""}${animation?"":" no-animation"}`}
                    style={{
                        height: `${actualHeight}rem`,
                        justifyContent: (layout==TBannerLayout.Left)?"flex-start":((layout==TBannerLayout.Right)?"flex-end":"center"),
                        transition: show?`opacity ${fadeInDuration}s,visibility ${fadeInDuration}s`:"none"
                    }}
                >
                <div className="frame"
                        style={{
                            width: `${width}rem`
                        }}
                    >
                    <Image src={src} width={width} height={actualHeight} onLoadingComplete={imageLoadedHandler} priority={true} />
                    {layout===TBannerLayout.Center?(
                        texts.map(({text,color,weight,family,size,left,top,url,letterSpacing = 0},index) => (
                            <div key={index} className="text" style={{
                                color: color,
                                fontFamily: family,
                                fontWeight: weight,
                                fontSize: `${percentageSize(size)}rem`,
                                letterSpacing: `${letterSpacing*percentageSize(size)}rem`,
                                left: `${left}%`,
                                top: `${top}%`,
                                ...(url?{cursor: "pointer"}:{}),
                                transition: show?`opacity ${textFadeInDuration}s,visibility ${textFadeInDuration}s`:"none",
                                transitionDelay: show?(fadeInDuration*textShowRatio+delayPerText*(index-0.2+(url?-0.3:0)))+"s":"0s"
                            }}>{text}</div>
                        ))
                    ):""}
                </div>
                {layout!==TBannerLayout.Center?(
                        texts.map(({text,color,weight,family,size,left,top,url,letterSpacing = 0},index) => (                            
                            <div key={index} className="text" style={{
                                color: color,
                                fontFamily: family,
                                fontWeight: weight,
                                fontSize: `${percentageSize(size)}rem`,
                                letterSpacing: `${letterSpacing*percentageSize(size)}rem`,
                                left: textLefts[index],
                                top: `${top}%`,
                                ...(url?{cursor: "pointer"}:{}),
                                transition: show?`opacity ${textFadeInDuration}s,visibility ${textFadeInDuration}s`:"none",
                                transitionDelay: show?(fadeInDuration*textShowRatio+delayPerText*(index-0.2+(url?-0.3:0)))+"s":"0s"
                            }}>{text}</div>
                        ))
                ):""}
                <div className="mobile-overlay"></div>
                <EventPane onClick={onClick} />
            </div>
            <style jsx>{`
            .container {
                width: 100%;
                display: flex;
                flex-direction: row;
                opacity: 0;
                visibility: hidden;
                position: relative;
            }

            .mobile-overlay {
                position: absolute;
                left: 0rem;
                top: 0rem;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,0.075);
                visibility: hidden;
                opacity: 0;
                --duration: var(--touch-duration);
                transition: opacity var(--duration),visibility var(--duration);
            }

            .container.touchdown .mobile-overlay {
                visibility: visible;
                opacity: 1;
            }

            .container.no-animation,.container.no-animation .text
            {
                transition: none;
            }            

            .container.show
            {
                opacity: 1;
                visibility: visible;
            }

            .frame {
                height: 100%;
                position: relative;
            }

            .text {
                position: absolute;
                white-space: nowrap;
                opacity: 0;
                visibility: hidden;
            }

            .container.show .text
            {
                opacity: 1;
                visibility: visible;
            }
            `}</style>
        </>
    );
}